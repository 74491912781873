import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import ArrowBack from "@material-ui/icons/ArrowBack";
import Favorite from "@material-ui/icons/Favorite";
// core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterLinks from "components/Footer/FooterLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
// sections for this page
import SectionDescription from "./Sections/SectionDescription.jsx";
import SectionServices from "./Sections/SectionServices.jsx";
import SectionContact from "./Sections/SectionContact.jsx";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.jsx";

class BlogPostPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          brand="Kinos Corp"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "info"
          }}
        />
        <Parallax image={require("assets/img/Kinos/Services/Background/investment.jpg")} filter="dark">
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem md={8} className={classes.textCenter}>
                <h1 className={classes.title}>
                  Servicios de inversiones
                </h1>
                <h4 className={classes.subtitle}>
                  Servicios de inversiones para inversores privados, corporativos e institucionales
                </h4>
                <br />
                  <Link to='/services'>
                    <Button color="rose" size="lg" round>
                      <ArrowBack /> Back
                    </Button>
                  </Link>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classes.main}>
          <div className={classes.container}>
            <SectionDescription />
            <SectionServices />
            <SectionContact />
          </div>
        </div>

        <Footer>
          <FooterLinks/>
        </Footer>
      </div>
    );
  }
}

export default withStyles(blogPostPageStyle)(BlogPostPage);
