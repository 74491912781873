import React from "react";
// nodejs library that concatenates classes
import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class FooterLinks extends React.Component {


  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <h5>Acerca de nosotros</h5>
          <p>

Kinos es una de las firmas de asesoría inmobiliaria comercial y residencial líderes en España.
ESTAMOS ORGULLOSOS DE NUESTRO ORIGEN ESPAÑOL.{" "}
          </p>
          <p>
            Brindamos una plataforma de servicios totalmente integrada a empresas familiares, corporaciones nacionales y multinacionales e inversores institucionales en todo el mundo.
            así como a ocupantes, propietarios y promotores de inmuebles a nivel local, regional y nacional...{" "}
          </p>
          <a href="https://twitter.com/kinosgroup?lang=en" className={classes.dropdownLink}>
            <i className="fab fa-twitter" />{" "}
          </a>
          <a href="https://www.facebook.com/Kinosgroup/" className={classes.dropdownLink}>
          <i className="fab fa-facebook-square" />{" "}
          </a>
          <a href="https://www.youtube.com/channel/UCakBgiVXW6jUQ7Dw5WiiDKw?feature=embeds_subscribe_title" className={classes.dropdownLink}>
          <i className="fab fa-youtube" />{" "}
          </a>
          <a href="https://www.instagram.com/KinosGroup/" className={classes.dropdownLink}>
          <i className="fab fa-instagram" />{" "}
          </a>
        </GridItem>

        <GridItem xs={12} sm={4} md={4}>

          <h5>Market with Kinos</h5>
          <div className={classes.socialFeed}>
            <div>
              <Link to="/market" className={classes.dropdownLink}>
                 <p>Registrar oferta</p>
              </Link>
            </div>
            <div>
            <Link to="/market" className={classes.dropdownLink}>
               <p>Registrar demanda</p>
            </Link>
            </div>

          </div>



          <h5>Propiedades</h5>
          <div className={classes.socialFeed}>
            <div>
            <a href="http://es.kinosgroup.com/hotels" className={classes.dropdownLink}>
               <p>Hoteles</p>
            </a>
            </div>
            <div>
            <a href="http://es.kinosgroup.com/malls" className={classes.dropdownLink}>
               <p>Centros comerciales</p>
            </a>
            </div>
            <div>
            <Link to="/retail" className={classes.dropdownLink}>
               <p>Locales</p>
            </Link>
            </div>
            <div>
            <a href="http://es.kinosgroup.com/industrial" className={classes.dropdownLink}>
               <p>Naves</p>
            </a>
            </div>
            <div>
            <a href="http://es.kinosgroup.com/offices" className={classes.dropdownLink}>
               <p>Oficinas</p>
            </a>
            </div>
            <div>
            <a href="http://es.kinosgroup.com/residential" className={classes.dropdownLink}>
               <p>Residencial</p>
            </a>
            </div>
            <div>
            <a href="http://es.kinosgroup.com/new_development_properties" className={classes.dropdownLink}>
               <p>Obra nueva</p>
            </a>
            </div>
            <div>
            <a href="http://es.kinosgroup.com/rural_lands" className={classes.dropdownLink}>
               <p>Fincas</p>
            </a>
            </div>
            <div>
            <a href="http://es.kinosgroup.com/urban_lands" className={classes.dropdownLink}>
               <p>Suelos</p>
            </a>
            </div>
            <div>
            <a href="http://es.kinosgroup.com/buildings" className={classes.dropdownLink}>
               <p>Edificios</p>
            </a>
            </div>
            <div>
            <a href="http://es.kinosgroup.com/investments" className={classes.dropdownLink}>
               <p>Proyectos</p>
            </a>
            </div>
            <div>
            <a href="http://es.kinosgroup.com/offer" className={classes.dropdownLink}>
               <p>Oportunidades de inversión</p>
            </a>
            </div>
          </div>

        </GridItem>

        <GridItem xs={12} sm={4} md={4}>

          <h5>Markets</h5>
          <div className={classes.socialFeed}>
            <div>
            <Link to="/demand" className={classes.dropdownLink}>
               <p>Demandas</p>
            </Link>
            </div>
            <div>
            <Link to="/offer" className={classes.dropdownLink}>
               <p>Ofertas</p>
            </Link>
            </div>
          </div>

          <h5>Areas</h5>
          <div className={classes.socialFeed}>
            <div>
              <Link to="/brokers" className={classes.dropdownLink}>
                 <p>Para Brokers</p>
              </Link>
            </div>
            <div>
              <Link to="/developers" className={classes.dropdownLink}>
                 <p>Para desarrolladores</p>
              </Link>
            </div>
            <div>
              <Link to="/investors" className={classes.dropdownLink}>
                 <p>Para inversores</p>
              </Link>
            </div>
            <div>
              <Link to="/relations" className={classes.dropdownLink}>
                 <p>Network</p>
              </Link>
            </div>
          </div>

          <h5>Servicios</h5>
          <div className={classes.socialFeed}>
            <div>
              <Link to="/buying-selling" className={classes.dropdownLink}>
                 <p>Intermediación compra venta</p>
              </Link>
            </div>
            <div>
              <Link to="/new-developments" className={classes.dropdownLink}>
                 <p>
Marketing y Ventas de Nuevos Desarrollos</p>
              </Link>
            </div>
            <div>
              <Link to="/assets-management" className={classes.dropdownLink}>
                 <p>Gestión de activos</p>
              </Link>
            </div>
            <div>
              <Link to="/familly-office" className={classes.dropdownLink}>
                 <p>Servicios de Family Office</p>
              </Link>
            </div>
            <div>
              <Link to="/finance" className={classes.dropdownLink}>
                 <p>Finance</p>
              </Link>
            </div>
            <div>
              <Link to="/investment-services" className={classes.dropdownLink}>
                 <p>Inversión</p>
              </Link>
            </div>
            <div>
              <Link to="/research" className={classes.dropdownLink}>
                 <p>Búsqueda</p>
              </Link>
            </div>
            <div>
              <Link to="/valuation" className={classes.dropdownLink}>
                 <p>Valoración</p>
              </Link>
            </div>
            <div>
              <Link to="/development" className={classes.dropdownLink}>
                 <p>Desarrollo urbanístico</p>
              </Link>
            </div>

          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(FooterLinks);
