import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import office2 from "assets/img/Kinos/investors-bg.jpg";
import blog8 from "assets/img/bg7.jpg";
import relations from "assets/img/Kinos/relations.jpg"
import cardProject6 from "assets/img/Kinos/Developers/card-project6.jpg";

import RegisterForm from "./RegisterForm.jsx";

import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.jsx";
import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionPills({ ...props }) {
  const { classes } = props;
  return (
    <div className={classes.section}>



      <GridContainer>

        <GridItem xs={12} sm={12} md={12}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + cardProject6 + ")" }}
          >
            <CardBody background>
              <h6 className={classes.category}>Eficiencia, seguridad, confidencialidad, integridad, transparencia</h6>
              <a href="#">
                <h3 className={classes.cardTitle}>
                  OPERACIONES DE RED GLOBAL CON TORUS
                </h3>
              </a>
              <p className={classes.category}>
                Disfrutamos enormemente de nuestra relación de trabajo con TORUS & PARTNERS NETWORK. Siempre están preparados y son proactivos, trayendo nuevas ideas a la mesa y ejecutándolas a un alto nivel. Las estrategias están bien concebidas y siempre con nuestros intereses y objetivos en mente.
              </p>
              <RegisterForm />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(sectionPillsStyle)(SectionPills);
