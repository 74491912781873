import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Separator from "@material-ui/icons/LinearScale";
import Bullet from "@material-ui/icons/DragIndicator";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Lo que ofrecemos</h2>
          <h5 className={classes.description}>
            La cultura de asociación de Kinos, nuestro compromiso con la excelencia y la integridad intransigente nos convierte en una excelente opción para inversores con una visión a largo plazo.
          </h5>

          <h5 className={classes.description}>
El interés de nuestro cliente primero es el núcleo de nuestra filosofía de gestión de activos.
              También adaptamos sus estrategias de inversión a las necesidades específicas de los clientes.
          </h5>
          <h5 className={classes.description}>Desarrollamos ideas y análisis que impulsan nuevas perspectivas, nuevas oportunidades y nuevos caminos de crecimiento.</h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="SERVICIOS DE ASESORÍA Y TRANSACCIÓN"
            description={
              <span>
                <p>

Los requisitos inmobiliarios de las empresas actuales son cada vez más complejos y requieren un mayor nivel de estrategia y sofisticación. En Kinos, ayudamos a nuestros clientes a tomar decisiones a medida para alinear y mejorar su propiedad inmobiliaria con su desempeño y objetivos comerciales generales.
Como firma de asesoría y transacciones en España y Portugal, Kinos ofrece conocimientos, experiencia y soluciones únicas para satisfacer las necesidades de los ocupantes e inversores inmobiliarios.
Kinos es capaz de brindar soluciones estratégicas para situaciones de adquisición, enajenación y financiamiento / refinanciamiento. Las soluciones incluirán asesoramiento sobre el servicio estratégico de préstamos sin recurso, reestructuración de préstamos y mejora de activos.
Para cada participación en todos los mercados de España y Portugal, nuestro enfoque basado en la estrategia combina a la perfección nuestros servicios de asesoría y servicios de ejecución líderes para proporcionar a nuestros clientes la información que necesitan para anticipar oportunidades, aprovechar la ventaja competitiva y ejecutar el mejor inmueble posible. estrategias.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="GESTION DE ACTIVOS"
            description={
              <span>
                <p>
                  Kinos proporciona una vista consolidada y precisa de los datos de su cartera de propiedades, así como una gestión integral de las propiedades.
Entendemos que, como inversor o gestor de activos, su prioridad es un edificio de oficinas que funcione. Por eso trabajamos con los mejores sistemas y las mejores personas.
Kinos considerará la inversión en el mismo barco o su función como proveedor de servicios de corretaje puro cuando sea necesario. La asesoría de Kinos también es capaz de proporcionar una variedad de soluciones para reducir los gastos operativos y a nivel de fondos para maximizar el flujo de efectivo de los activos.
Nuestro análisis de datos y conocimientos líderes en el mercado le brindarán una visión clara de su cartera de bienes raíces, lo que facilitará la administración de su propiedad al permitirle rastrear fácilmente el desempeño y la actividad del trabajo en progreso.
Usando poderosas herramientas de análisis, nuestra red de expertos lo ayudará a identificar eficiencias mientras alinea su propiedad inmobiliaria más estrechamente con sus objetivos corporativos.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="NETWORK"
            description={
              <span>
                <p>
                  Construyendo relaciones. Los negocios se basan en relaciones.
En un mundo global que está más conectado que nunca, las empresas se basan cada vez más en las relaciones. Kinos trabaja todo el tiempo en la construcción de buenas relaciones con inversores, propietarios, otros corredores, gobiernos, instituciones, empleados, proveedores de servicios, otros proveedores profesionales y partes interesadas. Tener buenas relaciones puede marcar la diferencia entre el éxito y el fracaso de una empresa.
Relaciones basadas en la excelencia
Nuestro equipo y red de Kinos están dedicados a proporcionar a los inversores y líderes empresariales soluciones personalizadas para ayudarles a navegar con éxito en el mercado inmobiliario comercial en constante evolución.
Nuestro éxito se basa en el desarrollo de relaciones a largo plazo, en la práctica de una disciplina financiera constante y en brindar a nuestros clientes un servicio dedicado y acceso a nuestro conjunto completo de soluciones y herramientas de gestión, el mejor de su clase.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="VENTA DE ACTIVOS"
            description={
              <span>
                <p>
                  Gestión patrimonial y corretaje.
Kinos proporciona a muchos de los inversores más sofisticados una plataforma global (Torus Network) impulsada por el talento inversor local.
Para lograr un fuerte crecimiento y altos retornos, buscamos construir posiciones de liderazgo en servicios de alto valor agregado para nuestros clientes. Por ejemplo, hemos incrementado sustancialmente la red de profesionales para mejorar y expandir nuestra capacidad de ejecutar ventas y adquisiciones En el mercado, estructuramos y ejecutamos transacciones pequeñas, medianas y grandes para family office o inversionistas institucionales, fondos de pensiones y clientes corporativos. En la gestión de activos, enfatizamos la equidad y los activos de inversión alternativos y utilizamos nuestra red internacional para construir una gestión de activos completa.
La red de profesionales de Kinos brinda servicios tales como abastecimiento, adquisición, suscripción y debida diligencia de activos inmobiliarios y de préstamos en dificultades y en buen desempeño, administración de activos, administración de carteras y disposición en varios tipos de propiedades.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >
          <h3 className={classes.title}>Estrategias de inversión</h3>
            <span>
              <p>
                <h5>Gestión de JV o Proceso de Desarrollo</h5>
                <h5>Modelado operativo financiero</h5>
                <h5>Presupuesto y control</h5>
                <h5>Estructura y especificaciones legales</h5>
                <h5>Interacción habilitada por la tecnología</h5>
                <h5>Informe financiero</h5>
                <h5>Informe operativo</h5>
                <h5>Estrategia de Marketing</h5>
                <h5>Estratgias de venta</h5>
                <h5>Análisis de costes del ciclo de vida</h5>
                <h5>Posicionamiento de activos</h5>
                <h5>Estrategias de salida</h5>
              </p>

            </span>
        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >
          <h3 className={classes.title}>Canales de inversión</h3>
            <span>

                <h6>Inversiones directas</h6>
                <h5>En activos inmobiliarios </h5>
                <h5>En proyectos</h5> 
                <h6>A través de intermediarios</h6>
                <h5>Gestión de inversiones </h5>
                <h5>Fondos de capital privado </h5>
                <h5>Fondos de infraestructura</h5> 
                <h5>Socimis</h5> 
                <h5>Reits</h5> 
                <h6>Otros vehículos de inversión conjunta...</h6>

            </span>
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(servicesStyle)(SectionServices);
