import React from "react";
import ReactMarkdown from "react-markdown";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import ReactStars from 'react-stars'

// Google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// core components
import ModalAsk from "./ModalAsk.jsx";
import Map from "./Map.jsx";
import Badge from "components/Badge/Badge.jsx";
import SectionCarousel from "./SectionCarousel.jsx";
import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

const CustomSkinMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 44.43353, lng: 26.093928 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 44.43353, lng: 26.093928 }} />
    </GoogleMap>
  ))
);


function SectionText({ ...props }) {
  const { classes } = props;
  const { property } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );

  return (
    <div className={classes.section}>

      <GridContainer justify="center">

        <GridItem xs={1} sm={1} md={1} className={classes.section}>

        </GridItem>

        <GridItem xs={10} sm={10} md={10} className={classes.section}>
          <GridContainer justify="center">

          <GridItem xs={12} sm={8} md={8} className={classes.section}>
            <SectionCarousel images={property.attributes.images} />
          </GridItem>

          <GridItem xs={12} sm={4} md={4} className={classes.section}>
            <br></br><br></br><br></br><br></br><br></br><br></br>
            <Quote
              textClassName={classes.quoteText}
              text={"Nombre: " + property.attributes.name}
              author={"Ref: " + property.attributes.ref}
            />
            { property.attributes.venta =! null &&
                <Badge color="info">En venta</Badge>


            }
            { property.attributes.alquiler =! null &&

                <Badge color="info">En alquiler</Badge>

            }
            <div className={classes.blogTags}>
              Etiquetas:
              { property.attributes.tags.map((tag) =>
                <Badge color="primary">{tag.name}</Badge>
              )}
            </div>

            <ReactMarkdown source={property.attributes.short_description} />
            <ModalAsk reference={property.attributes.ref} />

          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.bigMap}>
              <CustomSkinMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA42726T2lipqBYT7F5P98GFSH5rQ9MiSo"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `100%`,
                      borderRadius: "6px",
                      overflow: "hidden"
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          </GridItem>



          <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="info">Ubicación</CardHeader>

          <CardBody>
          <h5><strong>País: </strong>{property.attributes.pais.name}</h5>
          <h5><strong>Provincia: </strong>{property.attributes.provincia.name}</h5>
          <h5><strong>Localidad: </strong>{property.attributes.localidad.name }</h5>
          <h5><strong>Zona: </strong>{property.attributes.zona}<strong> CP: </strong>{property.attributes.cp}</h5>
          <h5><Map /></h5>
          </CardBody>
          </Card>

          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardHeader color="info">Datos</CardHeader>

            <CardBody>
              <h5><strong>Precio: </strong>{property.attributes.precio}</h5>
              <h5><strong>Superficie: </strong>{property.attributes.superficie}</h5>
              <h5><strong>Cert. Energética: </strong>{property.attributes.cert_energetica}</h5>
              <h5><strong>Licencia de apertura: </strong>{property.attributes.licencia_apertura }</h5>
              <h5><strong>Fecha de construcción: </strong>{property.attributes.fecha_construccion }</h5>
            </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">Descripción</CardHeader>
              <CardBody>
                <h3 className={classes.title}>
                  {property.attributes.name}
                </h3>
                <p>
                  <ReactMarkdown source={property.attributes.description} />
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        </GridItem>



        <GridItem xs={1} sm={1} md={1} className={classes.section}>

        </GridItem>




      </GridContainer>



      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>

        </GridItem>
      </GridContainer>



    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
