import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
// import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterLinks from "components/Footer/FooterLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.jsx";

// Consent
import CookieConsent, { Cookies } from "react-cookie-consent";

// Sections for this page
import SectionProduct from "./Sections/SectionProduct.jsx";
import SectionWork from "./Sections/SectionWork.jsx";
import SectionPricing from "./Sections/SectionPricing.jsx";
import ModalBuy from "./Sections/ModalBuy.jsx";
import ModalSell from "./Sections/ModalSell.jsx";
import ModalRent from "./Sections/ModalRent.jsx";
// Footer
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

class LandingPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          brand="Kinos Corp"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "info"
          }}
          {...rest}
        />
      <Parallax image={require("assets/img/Kinos/Properties/Background/03.jpg")} filter="dark">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <h1 className={classes.title}>Inmobiliaria empresarial</h1>
                <h2>Locales y Negocios</h2>
                <h4>
                  Encuentra el espacio adecuado para tu negocio.
                </h4>
                <br />
                <ModalBuy />
                <ModalSell />
                <ModalRent />
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>

            <SectionProduct />
            <SectionPricing />

            <SectionWork />
          </div>
        </div>

        <br></br><br></br>

        <Footer>
          <FooterLinks/>
        </Footer>
        <CookieConsent location="bottom" cookieName="AcceptCookie" expires={90} overlay buttonText="Entiendo y acepto" cookieName="myAwesomeCookieName2">
          Este sitio web utiliza cookies para mejorar la experiencia del usuario.
        </CookieConsent>
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
