import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.jsx";

function SectionPills({ ...props }) {
  const { classes, recentsList, recents } = props;

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
          <NavPills
            alignCenter
            tabs={[
              {
                tabButton: "Todas",
                tabContent: ""
              },
              {
                tabButton: "Oficinas",
                tabContent: ""
              },
              {
                tabButton: "Industrial",
                tabContent: ""
              },
              {
                tabButton: "Locales",
                tabContent: ""
              },
              {
                tabButton: "Residencial",
                tabContent: ""
              }
            ]}
          />
          <div className={classes.tabSpace} />
        </GridItem>
      </GridContainer>
      <GridContainer>

        <GridItem xs={12} sm={12} md={12}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + recentsList[0].attributes.adjunto + ")" }}
          >
            <CardBody background>
              <h6 className={classes.category}>FEATURED</h6>
              <a href="#pablo">
                <h3 className={classes.cardTitle}>
                  {recentsList[0].attributes.title}
                </h3>
              </a>
              <p className={classes.category}>
                {recentsList[0].attributes.leadin}
              </p>
              <Link to={"/blog-post/" + recentsList[0].id }>

              <Button round color="warning">
                <FormatAlignLeft className={classes.icons} /> Leer más...
              </Button>

              </Link>

            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>

        </GridItem>
        <GridItem xs={12} sm={6} md={6}>

        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionPillsStyle)(SectionPills);
