import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss";

// pages for this product

import LandingPage from "views/LandingPage/LandingPage.jsx";
import AboutUsPage from "views/AboutUsPage/AboutUsPage.jsx";
import PrivacyPage from "views/PrivacyPage/PrivacyPage.jsx";
import BlogPost from "views/BlogPost/BlogPost.jsx";
import BlogPostsPage from "views/BlogPostsPage/BlogPostsPage.jsx";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.jsx";

import BrokerPage from "views/BrokerPage/BrokerPage.jsx";
import InvestorsPage from "views/InvestorsPage/InvestorsPage.jsx";
import DevelopersPage from "views/DevelopersPage/DevelopersPage.jsx";
import RelationsPage from "views/RelationsPage/RelationsPage.jsx";
import MarketPage from "views/MarketPage/MarketPage.jsx";

import ServicesPage from "views/ServicesPage/ServicesPage.jsx";
import BuyingSellingPage from "views/services/BuyingSellingPage/BuyingSellingPage.jsx";
import NewDevelopmentsPage from "views/services/NewDevelopmentsPage/NewDevelopmentsPage.jsx";
import AssetsManagementPage from "views/services/AssetsManagementPage/AssetsManagementPage.jsx";
import FamilyOfficePage from "views/services/FamilyOfficePage/FamilyOfficePage.jsx";
import FinancePage from "views/services/FinancePage/FinancePage.jsx";
import InvestmentServicesPage from "views/services/InvestmentServicesPage/InvestmentServicesPage.jsx";
import ValuationPage from "views/services/ValuationPage/ValuationPage.jsx";
import ResearchPage from "views/services/ResearchPage/ResearchPage.jsx";
import DevelopmentPage from "views/services/DevelopmentPage/DevelopmentPage.jsx";

import PropertiesPage from "views/PropertiesPage/PropertiesPage.jsx";


import RetailPage from "views/assets/RetailPage/RetailPage.jsx";
import Retail from "views/assets/Retail/Retail.jsx";

import SuplyOpsPage from "views/assets/SuplyOpsPage/SuplyOpsPage.jsx";
import SuplyOp from "views/assets/SuplyOp/SuplyOp.jsx";
import DemandOpsPage from "views/assets/DemandOpsPage/DemandOpsPage.jsx";
import DemandOp from "views/assets/DemandOp/DemandOp.jsx";

import RegisterPage from "views/RegisterPage/RegisterPage.jsx";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>


      <Route path="/contact-us" component={ContactUsPage} />
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/privacy" component={PrivacyPage} />
      <Route path="/blog-posts" component={BlogPostsPage} />
      <Route path="/blog-post/:id" component={BlogPost} />
      <Route path="/brokers" component={BrokerPage} />
      <Route path="/investors" component={InvestorsPage} />
      <Route path="/developers" component={DevelopersPage} />
      <Route path="/relations" component={RelationsPage} />

      <Route path="/services" component={ServicesPage} />
      <Route path="/buying-selling" component={BuyingSellingPage} />
      <Route path="/new-developments" component={NewDevelopmentsPage} />
      <Route path="/assets-management" component={AssetsManagementPage} />
      <Route path="/family-office" component={FamilyOfficePage} />
      <Route path="/finance" component={FinancePage} />
      <Route path="/investment-services" component={InvestmentServicesPage} />
      <Route path="/valuation" component={ValuationPage} />
      <Route path="/research" component={ResearchPage} />
      <Route path="/development" component={DevelopmentPage} />

      <Route path="/properties" component={PropertiesPage} />

      <Route path="/retail" exact component={RetailPage} />
      <Route path="/retail/:id?" component={Retail} />

      <Route path="/offer" exact component={SuplyOpsPage} />
      <Route path="/offer/:id" component={SuplyOp} />
      <Route path="/demand" exact component={DemandOpsPage} />
      <Route path="/demand/:id" component={DemandOp} />

      <Route path="/market" component={MarketPage} />
      <Route path="/register" component={RegisterPage} />

      <Route path="/" component={LandingPage} />

    </Switch>
  </Router>,
  document.getElementById("root")
);
