import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import BulletOne from "@material-ui/icons/LooksOne";
import BulletTwo from "@material-ui/icons/LooksTwo";
import BulletThree from "@material-ui/icons/Looks3";
import BulletFour from "@material-ui/icons/Looks4";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>¿Qué ofrecemos?</h2>
          <h5 className={classes.description}>
            La cultura de asociación de Kinos, amplia  red de contactos profesionales, con nuestro compromiso con la excelencia y la integridad intransigente nos convierte en una excelente opción para inversores con una visión a largo plazo.
          </h5>

          <h5 className={classes.description}>El interés de nuestro cliente es el principal núcleo de nuestra filosofía de gestión de activos. También adaptamos sus estrategias de inversión a las necesidades específicas de los clientes.
</h5>
        </GridItem>
      </GridContainer>
      <GridContainer>


        <GridItem md={12} sm={12}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >

        <h3 className={classes.title}>¿Qué áreas cubrimos?</h3>

        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >

            <span>
              <p>
                <h5> Comercial </h5>
                <h5> Oficina </h5>
                <h5> Residencial </h5>
                <h5> Multifamiliar </h5>
                <h5> Minorista </h5>
                <h5> Atención médica </h5>

              </p>

            </span>
        </GridItem>

        <GridItem md={6} sm={6}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
          >
            <span>
            
              <h5> Hoteles y complejos turísticos </h5>
              <h5> Industrial y logística </h5>
              <h5> Tierra </h5>
              <h5> Agronegocios </h5>
              <h5> Golf </h5>
              <h5> Edificio ecológico </h5>


            </span>
        </GridItem>

      </GridContainer>
    </div>
  );
}


export default withStyles(servicesStyle)(SectionServices);
