import React from "react";
import axios from 'axios';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import TextField from '@material-ui/core/TextField';
import workStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.jsx";

class SectionWork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      isSent: false,
      formControls: {
        sender_name: '',
        sender_phone: '',
        sender_mail: '',
        message: '',
        subject: "Landing form message",
        website: "Kinoscorp EN",
        zone: ''


      }
    };
    this.onChange = this.onChange.bind(this);
  }

  //const [signupModal, setSignupModal] = React.useState(false);
  onChange = (e) => {

        const name = e.target.name;
        const value = e.target.value;

        this.setState({
          formControls: {
              ...this.state.formControls,
              [name]: value
            }
        });

      }

  onSubmit = (e) => {
        e.preventDefault();
        this.setState({isSent: true}) ;
        // get our form data out of state
        const { formControls } = this.state;

        axios.post('http://api.kinosgroup.com/api/v1/en/messages/contact', { formControls })
          .then((result) => {
            //access the results here....
          });
      }


  render() {
    const { classes } = this.props;
    const { formControls } = this.state;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={8} md={8}>
            <h2 className={classes.title}>¿Cómo podemos ayudarle?</h2>

            <h4 className={classes.description}>

¿QUIERE SER AGENTE ASOCIADO DE KINOS? ¡SEA PARTE DE GRAN GRUPO!
              Ser Agente de la marca Kinos Group es sinónimo de profesionalismo, innovación y calidad.
            </h4>


            <form>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <TextField
                      id="name"
                      label="Nombre"
                      name="sender_name"
                      className={classes.formControl}
                      margin="normal"
                      fullWidth
                      value={formControls.sender_name}
                      onChange={this.onChange}

                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <TextField
                      id="mail"
                      label="Correo"
                      name="sender_mail"
                      className={classes.formControl}
                      margin="normal"
                      fullWidth
                      value={formControls.sender_mail}
                      onChange={this.onChange}

                    />
                </GridItem>

                <TextField
                    id="message"
                    label="Mensaje"
                    name="message"
                    className={classes.textArea}
                    margin="normal"
                    fullWidth
                    multiline
                    rows={5}
                    value={formControls.message}
                    onChange={this.onChange}
                  />
                <GridItem
                  xs={12}
                  sm={4}
                  md={4}
                  className={`${classes.mrAuto} ${classes.mlAuto}`}
                >
                  { this.state.isSent ? (
                      <Button color="" onClick={this.onSubmit}>Mensaje enviado</Button>
                        ) : (
                      <Button color="primary" onClick={this.onSubmit}>Enviar mensaje</Button>
                     )}

                </GridItem>

              </GridContainer>
            </form>
            <h5 className={classes.description}>
              Tenga en cuenta que cualquier dato personal que usted y otros proporcionen a través de esta herramienta pueden ser procesados ​​por Kinos. La protección de sus datos personales es muy importante para Kinos. Kinos procesará sus datos personales de conformidad con las leyes de protección de datos aplicables.
Su decisión de proporcionar sus datos personales a Kinos es voluntaria. Sin embargo, dado que ciertas funcionalidades de esta herramienta requieren datos personales para funcionar, las funcionalidades no funcionarán si decide no proporcionar sus datos personales.
Kinos le invita a leer atentamente su <Link to = "/ privacy"> declaración de privacidad </Link>, que incluye información importante sobre por qué y cómo Kinos está procesando sus datos personales.
            </h5>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(SectionWork);
