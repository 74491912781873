import React from "react";
import axios from 'axios';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.jsx";

import contactStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/contactStyle.jsx";

class SectionContact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSent: false,

      formControls: {
        sender_name: '',
        sender_phone: '',
        sender_mail: '',
        message: '',
        subject: "I want to talk with you as...",
        website: "Kinoscorp EN - About Us",
        zone: '',
        speciality: 'Especialidad'

      }
    };
    this.onChange = this.onChange.bind(this);
  }

  //const [signupModal, setSignupModal] = React.useState(false);
  onChange = (e) => {

        const name = e.target.name;
        const value = e.target.value;

        if (name == 'speciality') {
          this.setState({
            formControls: {
                ...this.state.formControls,
                ['subject']:  'I want to talk with you as ' + value,
                [name]: value

              }
          });
        } else {
        this.setState({
          formControls: {
              ...this.state.formControls,
              [name]: value
            }
        });
      }
    }

  onSubmit = (e) => {
        e.preventDefault();
        this.setState({isSent: true}) ;
        // get our form data out of state
        const { formControls } = this.state;

        axios.post('http://api.kinosgroup.com/api/v1/en/messages/contact', { formControls })
          .then((result) => {
            //access the results here....
          });
      }



  render() {
    const { classes } = this.props;
    const { formControls } = this.state;

    return (
      <div className={classes.aboutUs}>
        <GridContainer>
          <GridItem
            md={8}
            sm={8}
            className={classNames(classes.mrAuto, classes.mlAuto)}
          >
            <h2 className={classNames(classes.title, classes.textCenter)}>
              ¿Quiere contactar con nosotros?
            </h2>
            <h4 className={classNames(classes.description, classes.textCenter)}>
              Divida los detalles sobre su producto o interés en partes. Escriba
              unas líneas sobre cada uno y contáctanos para más
              colaboración. Nos comunicaremos con usted en unas horas.
            </h4>
            <form>
              <GridContainer>
                <GridItem md={4} sm={4}>
                  <TextField
                      id="name"
                      label="Su Nombre"
                      name="sender_name"
                      className={classes.formControl}
                      margin="normal"
                      fullWidth
                      value={formControls.sender_name}
                      onChange={this.onChange}

                    />
                </GridItem>
                <GridItem md={4} sm={4}>
                  <TextField
                      id="mail"
                      label="Su correo"
                      name="sender_mail"
                      className={classes.formControl}
                      margin="normal"
                      fullWidth
                      value={formControls.sender_mail}
                      onChange={this.onChange}

                    />
                </GridItem>
                <GridItem md={4} sm={4}>
                  <TextField
                      id="phone"
                      label="Su teléfono"
                      name="sender_phone"
                      className={classes.formControl}
                      margin="normal"
                      fullWidth
                      value={formControls.sender_phone}
                      onChange={this.onChange}
                    />
                </GridItem>
                <GridItem md={4} sm={4}>
                  <FormControl
                    fullWidth
                    className={`${classes.selectFormControl} ${
                      classes.selectUnderlineRoot
                    }`}
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={formControls.speciality}
                      onChange={this.onChange}
                      inputProps={{
                        name: "speciality",
                        id: "speciality"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                        value="Especialidad"
                      >
                        Especialidad
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Broker"
                      >
                        Soy un Broker
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Investor"
                      >
                        Soy un inversor
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Seller"
                      >
                        Soy un vendedor
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>

                <GridItem md={8} sm={8}>
                </GridItem>

                <GridItem md={12} sm={12}>
                  <TextField
                      id="message"
                      label="Mensaje"
                      name="message"
                      className={classes.textArea}
                      margin="normal"
                      fullWidth
                      multiline
                      rows={5}
                      value={formControls.message}
                      onChange={this.onChange}
                    />
                </GridItem>

              </GridContainer>
              <GridContainer>
                <GridItem
                  md={4}
                  sm={4}
                  className={classNames(
                    classes.mrAuto,
                    classes.mlAuto,
                    classes.textCenter
                  )}
                >

                { this.state.isSent ? (
                    <Button color="">Mensaje enviado</Button>
                      ) : (
                    <Button color="primary" onClick={this.onSubmit}>Hablemos</Button>
                   )}

                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(contactStyle)(SectionContact);
