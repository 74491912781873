import React from "react";
import axios from 'axios';
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterLinks from "components/Footer/FooterLinks.jsx";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.jsx";

const CustomSkinMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: 36.7376333, lng: -4.4347258 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 36.7351762, lng: -4.43397 }} />
    </GoogleMap>
  ))
);

class ContactUsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSent: false,

      formControls: {
        sender_name: '',
        sender_phone: '',
        sender_mail: '',
        message: '',
        subject: "I want to contact",
        website: "Kinoscorp EN - Contact",
        zone: ''

      }
    };
    this.onChange = this.onChange.bind(this);
  }

  //const [signupModal, setSignupModal] = React.useState(false);
  onChange = (e) => {

        const name = e.target.name;
        const value = e.target.value;

        this.setState({
          formControls: {
              ...this.state.formControls,
              [name]: value
            }
        });

      }

  onSubmit = (e) => {
        e.preventDefault();
        this.setState({isSent: true}) ;
        // get our form data out of state
        const { formControls } = this.state;

        axios.post('http://api.kinosgroup.com/api/v1/en/messages/contact', { formControls })
          .then((result) => {
            //access the results here....
          });
      }


  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    const { formControls } = this.state;

    return (
      <div>
        <Header
          brand="Kinos Corp"
          links={<HeaderLinks dropdownHoverColor="dark" />}
          fixed
          color="dark"
        />
        <div className={classes.bigMap}>
          <CustomSkinMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA42726T2lipqBYT7F5P98GFSH5rQ9MiSo"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: `100%`,
                  borderRadius: "6px",
                  overflow: "hidden"
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.contactContent}>
            <div className={classes.container}>
              <h2 className={classes.title}>Envienos un mensaje</h2>
              <GridContainer>
                <GridItem md={6} sm={6}>
                  <p>

Contacta con nosotros para cualquier consulta, te responderemos en cuanto recibamos tu solicitud.
                    <br />
                    <br />
                  </p>
                  <form>
                    <TextField
                        id="name"
                        label="Su nombre"
                        name="sender_name"
                        className={classes.formControl}
                        margin="normal"
                        fullWidth
                        value={formControls.sender_name}
                        onChange={this.onChange}

                      />
                      <TextField
                          id="mail"
                          label="Su correo electrónico"
                          name="sender_mail"
                          className={classes.formControl}
                          margin="normal"
                          fullWidth
                          value={formControls.sender_mail}
                          onChange={this.onChange}

                        />
                        <TextField
                            id="phone"
                            label="Su teléfono"
                            name="sender_phone"
                            className={classes.formControl}
                            margin="normal"
                            fullWidth
                            value={formControls.sender_phone}
                            onChange={this.onChange}

                          />
                          <TextField
                              id="message"
                              label="Mensaje"
                              name="message"
                              className={classes.textArea}
                              margin="normal"
                              fullWidth
                              multiline
                              rows={5}
                              value={formControls.message}
                              onChange={this.onChange}
                            />
                    <div className={classes.textCenter}>
                      { this.state.isSent ? (
                          <Button color="" round>Mensaje enviado</Button>
                            ) : (
                          <Button color="primary" round onClick={this.onSubmit}>Contacte</Button>
                         )}
                    </div>
                  </form>
                  <h5>
      Tenga en cuenta que cualquier dato personal que usted y otros proporcionen a través de esta herramienta pueden ser procesados ​​por Kinos. La protección de sus datos personales es muy importante para Kinos. Kinos procesará sus datos personales de conformidad con las leyes de protección de datos aplicables.
      Su decisión de proporcionar sus datos personales a Kinos es voluntaria. Sin embargo, dado que ciertas funcionalidades de esta herramienta requieren datos personales para funcionar, las funcionalidades no funcionarán si decide no proporcionar sus datos personales.
      Kinos le invita a leer atentamente la <Link to = "/ privacy"> política de privacidad </Link>, que incluye información importante sobre por qué y cómo Kinos está procesando sus datos personales.</h5>
                </GridItem>
                <GridItem md={4} sm={4} className={classes.mlAuto}>
                  <InfoArea
                    className={classes.info}
                    title="Nuestras oficinas centrales"
                    description={
                      <p>
                        Avd. Simón Bolivar, 17 Local 24<br /> CP 29011, Málaga,{" "}
                        <br /> España
                      </p>
                    }
                    icon={PinDrop}
                    iconColor="primary"
                  />
                  <InfoArea
                    className={classes.info}
                    title="Nuestra oficina corporativa"
                    description={
                      <p>
                        C/ Alameda central, 6 3ª planta<br /> CP 29006, Málaga,{" "}
                        <br /> España
                      </p>
                    }
                    icon={PinDrop}
                    iconColor="primary"
                  />
                  <InfoArea
                    className={classes.info}
                    title="Llámenos"
                    description={
                      <p>
                        Grupo Kinos <br /> + 34 952 26 02 31 <br /> Mon - Fri,
                        9:00-21:00, Sat 8:00-14:00
                      </p>
                    }
                    icon={Phone}
                    iconColor="primary"
                  />
                  <InfoArea
                    className={classes.info}
                    title="Información legal"
                    description={
                      <p>
                        Kinos & Partners S.L. <br />  <br />  <br />
                      </p>
                    }
                    icon={BusinessCenter}
                    iconColor="primary"
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <Footer>
          <FooterLinks/>
        </Footer>
      </div>
    );
  }
}

export default withStyles(contactUsStyle)(ContactUsPage);
