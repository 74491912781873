import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
// @material-ui/icons
import Reply from "@material-ui/icons/Reply";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Assignment from "@material-ui/icons/Assignment";
import Money from "@material-ui/icons/Money";
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Collections from "@material-ui/icons/Collections";
import Check from "@material-ui/icons/Check";
// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";


import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Media from "components/Media/Media.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import TextField from '@material-ui/core/TextField';
// import sectionCommentsStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionCommentsStyle.jsx";
import sectionCommentsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ModalFilter extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        signupModal: false
      };
      // this.onChange = this.onChange.bind(this);
    }

  openModal = (e) => {
      this.setState({signupModal: true}) ;
    }
  closeModal = (e) => {
      this.setState({signupModal: false}) ;
    }


  render () {

      const { classes } = this.props;
      const { formControls, total } = this.props;

      return (
        <div>
            <div className={classes.textCenter}>
              <Button color="primary" round onClick={this.openModal} >
                Filtrar resultados
              </Button>
            </div>


        <Dialog
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSignup
            }}
            open={this.state.signupModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.closeModal}
            aria-labelledby="signup-modal-slide-title"
            aria-describedby="signup-modal-slide-description"
          >
            <Card plain className={classes.modalSignupCard}>
              <DialogTitle
                id="signup-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  color="primary"
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={this.closeModal}
                >
                  {" "}
                  <Close className={classes.modalClose} />
                </Button>

              </DialogTitle>
              <DialogContent
                id="signup-modal-slide-description"
                className={classes.modalBody}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
                  <div className={classes.textCenter}>
                    { (total == 0) ?
                      <div>
                        <h2>No hay resultados. Contactenos por email o cambie su criterio de búsqueda.</h2>
                        <Link to="/contact-us">
                          <Button color="info" size="lg" round>Contactar</Button>
                        </Link>
                      </div> :
                      <h2 className={classes.socialTitle}>Resultados del filtro: {total} propiedades</h2>
                    }
                  </div>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>

                    <form className={classes.form}>
                        <TextField
                            id="name"
                            label="Palabra clave"
                            name="name"
                            className={classes.textField}
                            margin="normal"
                            fullWidth
                            value={formControls.name}
                            onChange={this.props.handleChange}
                          />


                        <TextField
                            id="min_price"
                            label="Precio mínimo"
                            name="min_price"
                            className={classes.textField}
                            margin="normal"
                            fullWidth
                            value={formControls.min_price}
                            onChange={this.props.handleChange}
                          />

                          <TextField
                              id="sup_max"
                              label="Superficie máxima"
                              name="sup_max"
                              className={classes.textField}
                              margin="normal"
                              fullWidth
                              value={formControls.sup_max}
                              onChange={this.props.handleChange}
                            />

                      <FormControlLabel
                        classes={{
                          label: classes.label
                        }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => this.handleToggle(1)}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        label={
                          <span>
                            On rent
                          </span>
                        }
                      />

                    </form>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6} className={classes.mrAuto}>

                    <form className={classes.form}>


                      <TextField
                          id="zone"
                          label="Zona"
                          name="zone"
                          className={classes.textField}
                          margin="normal"
                          fullWidth
                          value={formControls.zone}
                          onChange={this.props.handleChange}
                        />

                      <TextField
                          id="max_price"
                          label="Precio máximo"
                          name="max_price"
                          className={classes.textField}
                          margin="normal"
                          fullWidth
                          value={formControls.max_price}
                          onChange={this.props.handleChange}
                        />


                        <TextField
                            id="sup_min"
                            label="Superficie mínima"
                            name="sup_min"
                            className={classes.textField}
                            margin="normal"
                            fullWidth
                            value={formControls.sup_min}
                            onChange={this.props.handleChange}
                          />

                      <FormControlLabel
                        classes={{
                          label: classes.label
                        }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => this.handleToggle(1)}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        label={
                          <span>
                            En venta
                          </span>
                        }
                      />

                    </form>
                  </GridItem>

                  <div className={classes.textCenter}>
                    <Button round color="primary" onClick={this.closeModal}>
                      Filtrar
                    </Button>
                  </div>

                </GridContainer>

              </DialogContent>
            </Card>
          </Dialog>
        </div>
      );
    }
}

export default withStyles(sectionCommentsStyle)(ModalFilter);
