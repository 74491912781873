import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Subject from "@material-ui/icons/Subject";
import Delete from "@material-ui/icons/Delete";
// core components
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Info from "components/Typography/Info.jsx";
import Button from "components/CustomButtons/Button.jsx";

import AssetCard from "./AssetCard.jsx"

// import sectionSimilarStoriesStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle.jsx";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";


class SectionRecents extends React.Component {

  constructor(props) {
    super(props);

  }



  renderCards = (properties) => {
    const { classes } = this.props;
    if (typeof properties !== 'undefined' && properties.length > 0) {
      return properties.map(property =>
        <GridItem xs={12} sm={4} md={4} lg={4} key={property.id}>
          <AssetCard asset={property} />
        </GridItem>
      )
    } else {
      return(
      <GridItem xs={12} sm={6} md={6} lg={4}>
          <h5>Sin imagenes</h5>
      </GridItem>
    )}
  };



  render() {
    const { classes } = this.props;
    const { recents } = this.props;
    return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <h2 className={classes.title + " " + classes.textCenter}>
              Locales añadidos recientemente
            </h2>
            <br />
            <GridContainer>
              {this.renderCards(recents)}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
  }
}

export default withStyles(styles)(SectionRecents);
