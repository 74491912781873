import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import brokers from "assets/img/Kinos/brokers.jpg";
import developers from "assets/img/Kinos/Developers/developers.jpg";
import relations from "assets/img/Kinos/relations.jpg"
import cardProject6 from "assets/img/Kinos/Developers/card-project6.jpg";

import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.jsx";
import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionPills({ ...props }) {
  const { classes } = props;
  return (
    <div className={classes.section}>



      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + brokers + ")" }}
          >
            <CardBody background>
              <h6 className={classes.category}>BROKERS</h6>
              <a href="/brokers">
                <h3 className={classes.cardTitle}>
                  Brokers & Brokerage services, connecting investors and sellers
                </h3>
              </a>
              <p className={classes.category}>
                Kinos siempre está buscando oportunidades para inversores. Tenemos una gran cartera que incluye reposicionamiento de activos individuales, desinversiones corporativas, programas de empresas conjuntas, inversiones en entidades, oportunidades de desarrollo y acuerdos de deuda. Los activos pueden adquirir individualmente o en operaciones de cartera.
              </p>
              <Button round href="/brokers" color="danger">
                <FormatAlignLeft className={classes.icons} /> Leer más
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + developers + ")" }}
          >
            <CardBody background>
              <h6 className={classes.category}>PROMOTORES</h6>
              <a href="/developers">
                <h3 className={classes.cardTitle}>
                  Servicios para constructores, promotores inmobiliarios e inversores
                </h3>
              </a>
              <p className={classes.category}>

Kinos Land & New Homes nuestro objetivo es simplificar el proceso de compra y venta de nuevas viviendas y propiedades relacionadas, incluidos el intercambio de piezas y los esquemas asistidos. Nuestra estrategia es crear alianzas con desarrolladores, constructores y todo tipo de socios para apoyar sus objetivos y agregar valor a sus negocios.
              </p>
              <Button round href="/developers" color="primary">
                <FormatAlignLeft className={classes.icons} /> Leer más
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={4} md={4}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + relations + ")" }}
          >
            <CardBody background>
              <h6 className={classes.category}>NetWork</h6>
              <a href="/relations">
                <h3 className={classes.cardTitle}>
                  Estamos dispuestos a compartir nuestro conocimiento y experiencia del mercado con usted para satisfacer sus objetivos.
                </h3>
              </a>
              <p className={classes.category}>
                El objetivo principal es la construcción de relaciones a largo plazo basadas en el saber hacer, la confianza y la confidencialidad con los principales actores de cada sector.
              </p>
              <Button round href="/relations" color="danger">
                <FormatAlignLeft className={classes.icons} /> Read more
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + cardProject6 + ")" }}
          >
            <CardBody background>
              <h6 className={classes.category}>Eficiencia, seguridad, confidencialidad, integridad, transparencia</h6>
              <a href="/register">
                <h3 className={classes.cardTitle}>
                  OPERACIONES DE RED GLOBAL CON TORUS
                </h3>
              </a>
              <p className={classes.category}>
                Disfrutamos enormemente de nuestra relación de trabajo con TORUS & PARTNERS NETWORK. Siempre están preparados y son proactivos, trayendo nuevas ideas a la mesa y ejecutándolas a un alto nivel. Las estrategias están bien concebidas y siempre con nuestros intereses y objetivos en mente.
              </p>
              <Button round href="/register" color="warning">
                <FormatAlignLeft className={classes.icons} /> Leer más
              </Button>

            </CardBody>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionPillsStyle)(SectionPills);
