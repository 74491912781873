import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.jsx";
import image1 from "assets/img/image_placeholder.jpg";

class SectionCarousel extends React.Component {

  renderSlides = (images) => {

    if (typeof images !== 'undefined' && images.length > 0) {
      return images.map(image =>
        <div key={image.id}>
          <img
            src={image.attributes.adjunto_medium}
            alt={image.attributes.title}
            className="slick-image"
          />
          <div className="slick-caption">
            <h4>
              <LocationOn className="slick-icons" />
              {image.attributes.title} {image.attributes.description}
            </h4>
          </div>
        </div>
      )
    } else {
      return(
        <div>
          <img
            src={image1}
            alt="First slide"
            className="slick-image"
          />
          <div className="slick-caption">
            <h4>
              Sin imágenes
            </h4>
          </div>
        </div>
    )}
  };

  render() {
    const { classes } = this.props;
    const { images } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false
    };
    return (
      <div className={classes.section} id="carousel">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={10} md={10} className={classes.marginAuto}>
              <Card>
                <Carousel {...settings}>
                  {this.renderSlides(images)}
                </Carousel>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(carouselStyle)(SectionCarousel);
