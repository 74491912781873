import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.description}>

            Elegidos por nuestro servicio. Famoso por nuestro resultados
          </h2>
          <h3>
Combinando nuestro conocimiento del mercado regional con la experiencia de la industria, brindamos un servicio completo en todos los asuntos inmobiliarios para propietarios, ocupantes, instituciones financieras, desarrolladores e inversores.</h3>
          <h5>

Creemos en poner a nuestros clientes en primer lugar y brindarles asesoramiento eficaz y con visión de futuro de una manera accesible que, en última instancia, lo ayude a lograr sus objetivos comerciales y obtener el máximo valor de su propiedad.
          </h5>
          <h5>
Encuentre su próxima inversión o oportunidad de arrendamiento. Nuestros listados incluyen propiedades de oficinas, industriales y comerciales, así como espacios especializados para multifamiliares, atención médica, tecnología y más.</h5>
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
