import React from "react";
import axios from 'axios';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterLinks from "components/Footer/FooterLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

// sections for this page
import SectionPills from "./Sections/SectionPills.jsx";
import SectionNewsList from "./Sections/SectionNewsList.jsx";
import SubscribeLine from "./Sections/SubscribeLine.jsx";

import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.jsx";

class BlogPostsPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      newsList: [],
      recentsList: [ { id: '', attributes: {} } ],
      featured: [],
      per_page: 15,
      total: 0
      };
    }

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    let urlResults = 'http://api.kinosgroup.com/api/v1/es/news?portal=6&per_page=' + this.state.per_page ;
    let urlFeatured = 'http://api.kinosgroup.com/api/v1/es/news?portal=6&per_page=3&sort=categoria&direction=desc' ;
    let urlRecents = 'http://api.kinosgroup.com/api/v1/es/news?portal=6&per_page=3&sort=updated_at&direction=desc' ;

    const requestResults = axios.get(urlResults);
    const requestFeatured = axios.get(urlFeatured);
    const requestRecents = axios.get(urlRecents);

    axios
      .all([requestResults, requestFeatured, requestRecents])
      .then(
          axios.spread((...responses) => {
            const responseResults = responses[0];
            const responseFeatured = responses[1];
            const responseRecents = responses[2];

            if (this._isMounted) {
              this.setState({ newsList: responseResults.data.data });
              this.setState({ featured: responseFeatured.data.data });
              this.setState({ recentsList: responseRecents.data.data });
              this.setState({ total: responseResults.data.meta.total} );
              this.setState({ per_page: responseResults.data.meta.per_page} );
              console.log(responseResults.data.data, responseFeatured.data.data, responseRecents.data.data);
            }

          })
        )
        .catch(errors => {
          // react on errors.
          console.error(errors);
        });
}

  handleViewMoreResults = () => {

      let per_page = this.state.per_page;
      let total = this.state.total;

      per_page = +per_page+6;

      if (per_page > total) { per_page = total; }

      let url = 'http://api.kinosgroup.com/api/v1/es/news?per_page=' + per_page ;

      axios
        .get(url)
        .then(response => {
              if (this._isMounted) {
              this.setState({ newsList: response.data.data });
              this.setState({ per_page });
              console.log(response.data.data);
              }
          })
          .catch(errors => {
            console.error(errors);
          });

    };

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
    const { classes } = this.props;
    const { newsList, featured, recentsList, total, per_page } = this.state;
    return (
      <div>
        <Header
          brand="Kinos Corp"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "info"
          }}
        />
        <Parallax image={require("assets/img/bg10.jpg")} filter="dark" small>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
                <h2 className={classes.title}>

Un lugar para que los inversores compartan y descubran noticias e informes del sector
                </h2>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main}>
          <div className={classes.container}>
            <SectionPills recentsList={recentsList} />
            <SectionNewsList news={newsList} total={total} perPage={per_page} handleViewMoreResults={this.handleViewMoreResults} />
          </div>

          <SubscribeLine />
        </div>
        <Footer>
          <FooterLinks/>
        </Footer>
      </div>
    );
  }
}

export default withStyles(blogPostsPageStyle)(BlogPostsPage);
