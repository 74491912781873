import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Info from "components/Typography/Info.jsx";
import Paginations from "components/Pagination/Pagination.jsx";
import sectionSimilarStoriesStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle.jsx";

// Default image
import defaultImage from "assets/img/Kinos/Properties/default_image.jpg";

function SectionResults({ ...props }) {
  const { classes, handleViewMoreResults, total, perPage } = props;
  // const { locals } = props;



  var locales = props.locales.map( (local) => {
    let url = '/retail/' + local.id;
    let images = local.attributes.images;

    if (typeof images !== 'undefined' && images.length > 0) {
      var image = local.attributes.images[0].attributes.adjunto;
     } else {
      var image = defaultImage;
    }

    return(

      <GridItem xs={12} sm={4} md={4}>
        <Card blog>
          <CardHeader image>
            <Link to={{
                        pathname: url,
                        state: {
                          local: local.attributes
                        }
                      }}>
              <img src={image} alt="..." />
            </Link>
            <div
              className={classes.coloredShadow}
              style={{
                backgroundImage: "url(" + image + ")",
                opacity: "1"
              }}
            />
          </CardHeader>
          <CardBody>
            <Info>
              <h6>REF: {local.attributes.ref}</h6>
            </Info>
            <h4 className={classes.cardTitle}>
              <Link to={{
                          pathname: url,
                          state: {
                            local: local.attributes
                          }
                        }}>
                {local.attributes.name.es}
              </Link>
            </h4>
            <p className={classes.description}>
              {local.attributes.short_description}...
              <Link to={{
                          pathname: url,
                          state: {
                            local: local.attributes
                          }
                        }}> Leer más
              </Link>
            </p>
          </CardBody>
        </Card>
      </GridItem>

    )
  })

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>

          <GridItem md={12}>
            <h2 className={classes.title + " " + classes.textCenter}>
              { (perPage < total )
               ? `Mostrando ${perPage} de un total de ${total}`
               : `Mostrando un total de ${total} resultados`
              }
            </h2>

            <br />

            <GridContainer>
              {locales}
            </GridContainer>

            <br />

            <h2 className={classes.title + " " + classes.textCenter}>
                { (perPage < total )
                 ? `Mostrando ${perPage} de un total de ${total}`
                 : `Mostrando un total de ${total} resultados`
                }
              </h2>

          </GridItem>

          <GridItem md={12} className={classes.textCenter}>
            { (perPage < total )
                ? <Button round onClick={handleViewMoreResults} color="primary">
                  Ver más...
                </Button>
                : ''
            }
          </GridItem>

        </GridContainer>
      </div>
    </div>
  );
}

export default withStyles(sectionSimilarStoriesStyle)(SectionResults);
