import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
//import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Info from "components/Typography/Info.jsx";
// import Success from "components/Typography/Success.jsx";
// import Danger from "components/Typography/Danger.jsx";

import malls from "assets/img/Kinos/Properties/Thumbnails/malls.jpg";
import offices from "assets/img/Kinos/Properties/Thumbnails/offices.jpg";
import hotels from "assets/img/Kinos/Properties/Thumbnails/hotel.jpg";
import industrial from "assets/img/Kinos/Properties/Thumbnails/industrial.jpg";
import retail from "assets/img/Kinos/Properties/Thumbnails/retail.jpg";
import ruralLands from "assets/img/Kinos/Properties/Thumbnails/rural_lands-bg.jpg";
import bo_offer from "assets/img/Kinos/Properties/Thumbnails/projects.jpg";
// import lands from "assets/img/Kinos/lands.jpg";
import urbanLands from "assets/img/Kinos/Properties/Thumbnails/urban_lands.jpeg";
import buildings from "assets/img/Kinos/Properties/Thumbnails/buildings.jpg";
import residential from "assets/img/Kinos/Properties/Thumbnails/residential.jpg";
import new_developments from "assets/img/Kinos/Properties/Thumbnails/new_developments.jpg";
import investments from "assets/img/Kinos/Properties/Thumbnails/investments01.jpg";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.jsx";

function SectionProperties({ ...props }) {
  const { classes } = props;
  const { stats } = props;
  return (
    <div className={classes.section}>
      <h3 className={classes.title + " " + classes.textCenter}>
        Puede estar interesado en...
      </h3>
      <br />
      <GridContainer>

        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <Link to="/retail" className={classes.dropdownLink}>
                <img src={retail} alt="Retail" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + retail + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>Tenemos {stats.locales.count} locales enb venta / alquiler</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/retail" className={classes.dropdownLink}>
                  LOCALES COMERCIALES
                </Link>
              </h4>
              <p className={classes.description}>
                La división de Corretaje Comercial se compromete a mejorar la rentabilidad y el valor de los activos inmobiliarios comerciales de nuestros clientes.
                Brindamos representación de inquilinos, arrendamiento de proyectos, corretaje general, ventas de inversiones y propiedad / disposición excedente...
                <Link to="/retail" className={classes.dropdownLink}>Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <Link to="/industrial" className={classes.dropdownLink}>
                <img src={industrial} alt="Industrial" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + industrial + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>Tenemos {stats.naves.count} naves en venta</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/industrial" className={classes.dropdownLink}>
                  NAVES INDUSTRIALES
                </Link>
              </h4>
              <p className={classes.description}>

                Gestionamos venta y alquiler propiedades Industriales y Logísticas.
                Asesoramos y damos servicios completos en áreas de España y Portugal, gestionamos carteras de activos logísticos e industriales.
                 Nuestros servicios incluyen asesoría, gestión de transacciones, gestión para ocupantes e inversores durante todo el ciclo de vida inmobiliario...

                <Link to="/industrial" className={classes.dropdownLink}>Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <Link to="/offices" className={classes.dropdownLink}>
                <img src={offices} alt="Offices" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + offices + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>Tenemos {stats.oficinas.count} oficinas en venta</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/offices" className={classes.dropdownLink}>
                  OFICINAS Y ESPACIOS DE NEGOCIO
                </Link>
              </h4>
              <p className={classes.description}>
                Encuentre un espacio de oficina adecuado para su negocio.
                Nuestros  especialistas en gestión de de inquilinos mapearán las instalaciones disponibles en el mercado que satisfagan las necesidades y requisitos de su empresa.
                Nuestros socios son propietarios, empresas constructoras e inversores, por lo que estamos seguros de encontrar el espacio y la ubicación perfectos para usted...
                <Link to="/offices" className={classes.dropdownLink}>Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <Link to="/buildings" className={classes.dropdownLink}>
                <img src={buildings} alt="Buildings" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + buildings + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>Tenemos {stats.edificios.count} edificios en venta</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/buildings" className={classes.dropdownLink}>
                  EDIFICIOS
                </Link>
              </h4>
              <p className={classes.description}>
                Transacciones de arrendamiento, venta y adquisición de edificios.
                Como su socio gestor inmobiliario, Kinos le da una imagen completa del mercado e identifica los edificios en las mejores zonas.
                Optimizamos su cartera de edificios singulares...
                <Link to="/buildings" className={classes.dropdownLink}>Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>


        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <Link to="/hotels" className={classes.dropdownLink}>
                <img src={hotels} alt="Hotels" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + hotels + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>Tenemos {stats.hoteles.count} hoteles en venta</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/hotels" className={classes.dropdownLink}>
                  HOTELES
                </Link>
              </h4>
              <p className={classes.description}>

                Broker Hotelero. Proyectos Hoteleros, Adquisiciones, Due Diligence, Viabilidad, Gestión de Activos.
Asesoramos a fondos, bancos, aseguradoras, grupos inmobiliarios, inversores privados, REITs.
Consultoría boutique. Altos ejecutivos hoteleros. Décadas de experiencia....
                <Link to="/hotels" className={classes.dropdownLink}>Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>


        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <Link to="/malls" className={classes.dropdownLink}>
                <img src={malls} alt="SHOPPING CENTERS & MALLS" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + malls + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>Tenemos {stats.centros_comerciales.count} centros comerciales en venta</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/malls" className={classes.dropdownLink}>
                  CENTROS COMERCIALES
                </Link>
              </h4>
              <p className={classes.description}>
                Comprar o vender un centro comercial minorista tiene el potencial de ser una transacción muy rentable. Si se hace correctamente,
                puede generar un flujo estable de ingresos por alquiler y una apreciación constante del capital, lo que resulta en un rendimiento sustancial para el propietario al salir.
                Es una gran compra que requiere una reflexión considerable, recursos, experiencia operativa y debida diligencia...
                <Link to="/malls" className={classes.dropdownLink}>Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>


        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <Link to="/residential" className={classes.dropdownLink}>
                <img src={residential} alt="Residential" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + residential + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>Tenemos {stats.viviendas.count} viviendas en venta</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/residential" className={classes.dropdownLink}>
                  RESIDENCIAL
                </Link>
              </h4>
              <p className={classes.description}>
                Amplia red de agentes y colaboradores nacional e internacional especializados en inmuebles residenciales.
                Nuestros estándares pueden marcar la diferencia al vender o comprar su casa o comercializar su proyecto de obra nueva.
                Déjenos asesorarle en la venta o compra de su casa o complejo  residencial...
                <Link to="/residential" className={classes.dropdownLink}>Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <Link to="/new_development_properties" className={classes.dropdownLink}>
                <img src={new_developments} alt="Lands" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + new_developments + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>Tenemos {stats.promociones.count} promociones en venta</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/new_development_properties" className={classes.dropdownLink}>
                  OBRA NUEVA
                </Link>
              </h4>
              <p className={classes.description}>
                ¿Quiere invertir? ¿O comprar nueva casa?
                Puede reunirse con nuestro gestor de  ventas residencial, comercializamos los mejores proyectos de obra nueva.
                Gestionamos las venta de complejos de  apartamentos urbanos, primera y segunda residencia, resort vacacional, viviendas de golf o playa, su propiedad ideal está a solo un clic de distancia...
                <Link to="/new_development_properties" className={classes.dropdownLink}>Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <Link to="/urban_lands" className={classes.dropdownLink}>
                <img src={urbanLands} alt="Lands" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + urbanLands + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>Tenemos {stats.suelos.count} suelos en venta</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/urban_lands" className={classes.dropdownLink}>
                  SUELOS
                </Link>
              </h4>
              <p className={classes.description}>
                Terrenos urbanizables o urbanos para su  desarrollo o edificación directa.
                Parcelas urbanas comerciales, suelos para  oficinas y suelos para  desarrollos industriales.
                Lotes de suelos  residenciales y dotacionales para residencias de estudiantes u otros usos...
                <Link to="/urban_lands" className={classes.dropdownLink}>Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <Link to="/rural_lands" className={classes.dropdownLink}>
                <img src={ruralLands} alt="Lands" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + ruralLands + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>Tenemos {stats.fincas.count} fincas en venta</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/rural_lands" className={classes.dropdownLink}>
                  FINCAS
                </Link>
              </h4>
              <p className={classes.description}>
                Con una gran cantidad de propiedades rurales a la venta en España y Portugal, nuestra red de corredores han obtenido algunas de las mejores propiedades en venta de la oferta existente. Vea nuestra increíble ofertas de activos rurales en venta y explotaciones agrícolas. Damos apoyo profesional para la inversión en activos agrícolas.
Asesoramos e informamos de  detalles como derechos sobre minerales, agua y maderas que condicionan el valor de las fincas...
                <Link to="/rural_lands" className={classes.dropdownLink}>Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>





        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <Link to="/investments" className={classes.dropdownLink}>
                <img src={investments} alt="..." />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + investments + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>Tenemos {stats.proyectos.count} investment oportunities </h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/investments" className={classes.dropdownLink}>
                  INVERSIONES/PROYECTOS
                </Link>
              </h4>
              <p className={classes.description}>
                Fuente de oportunidades de negocios, gestión de proyectos y financiación
                Obtenenos y gestionamos ofertas de carteras activos inmobiliarios, carteras de deuda, lotes de venta de  créditos así como otras inversiones alternativas en España.
                Impulsamos el flujo de transacciones, mejoramos la diligencia debida y conectamos con oportunidades e inversores...
                <Link to="/investments" className={classes.dropdownLink}>Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>



        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <Link to="/offer" className={classes.dropdownLink}>
                <img src={bo_offer} alt="Operations" />
              </Link>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + bo_offer + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>Tenemos {stats.operaciones_oferta.count} operaciones en marcha</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <Link to="/offer" className={classes.dropdownLink}>
                  OPORTUNIDADES DE NEGOCIO
                </Link>
              </h4>
              <p className={classes.description}>
                Oferta de oportunidad de inversión inmobiliaria.
                Oportunidades de inversión en bienes inmuebles comerciales, lotes de  inmuebles de medio y alto valor.
                Aquí encontrará las ofertas y oportunidades recientes  para inversores inmobiliarios
                ...
                <Link to="/offer" className={classes.dropdownLink}>Leer más </Link>
              </p>
            </CardBody>
          </Card>
        </GridItem>



        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionInterestedStyle)(SectionProperties);
