import React from "react";
import axios from 'axios';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Mail from "@material-ui/icons/Mail";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import TextField from '@material-ui/core/TextField';

import sectionSubscribeLineStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionSubscribeLineStyle.jsx";

class SubscribeLine extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      signupModal: false,

      formControls: {
        sender_name: '',
        sender_phone: '',
        sender_mail: '',
        message: 'Please subscribe me to your newsletter',
        subject: "Subscribe to the news",
        website: "Kinoscorp ES - Blog",
        zone: ''


      }
    };
    this.onChange = this.onChange.bind(this);
  }

  //const [signupModal, setSignupModal] = React.useState(false);
  onChange = (e) => {

        const name = e.target.name;
        const value = e.target.value;

        this.setState({
          formControls: {
              ...this.state.formControls,
              [name]: value
            }
        });

      }

  onSubmit = (e) => {
        e.preventDefault();
        this.setState({signupModal: false}) ;
        // get our form data out of state
        const { formControls } = this.state;

        axios.post('http://api.kinosgroup.com/api/v1/en/messages/contact', { formControls })
          .then((result) => {
            //access the results here....
          });
      }


  render () {
    const { classes } = this.props;
    const { formControls } = this.state;
    //
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h3 className={classes.title}>¡Reciba asesoramiento e información!</h3>
              <p className={classes.description}>
                ¡Únase a nuestro boletín y reciba nuestras noticias en su bandeja de entrada todas las semanas! Odiamos el
                spam también, así que no se preocupe por eso.
              </p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card plain className={classes.cardClasses}>
                <CardBody formHorizontal plain>
                  <form>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={8}
                        md={8}
                        className={classes.alignItemsCenter}
                      >
                      <TextField
                          id="mail"
                          label="Correo electrónico"
                          name="sender_mail"
                          className={classes.formControl}
                          margin="normal"
                          fullWidth
                          value={formControls.sender_mail}
                          onChange={this.onChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Mail className={classes.icon} />
                              </InputAdornment>
                            ),
                          }}
                        />

                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <Button round block color="primary" onClick={this.onSubmit}>
                          Subscribirse
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(sectionSubscribeLineStyle)(SubscribeLine);
