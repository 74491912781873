import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
// Icons
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Refresh from "@material-ui/icons/Refresh";
import Subject from "@material-ui/icons/Subject";
import Delete from "@material-ui/icons/Delete";

// Images


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import Info from "components/Typography/Info.jsx";
import Danger from "components/Typography/Danger.jsx";
import Success from "components/Typography/Success.jsx";
import Warning from "components/Typography/Warning.jsx";
import Rose from "components/Typography/Rose.jsx";
import Button from "components/CustomButtons/Button.jsx";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";

// const styles = theme => ({
//   card: {
//     maxWidth: 400,
//   },
//   media: {
//     height: 0,
//     paddingTop: '56.25%', // 16:9
//   },
//   actions: {
//     display: 'flex',
//   },
//   expand: {
//     transform: 'rotate(0deg)',
//     marginLeft: 'auto',
//     transition: theme.transitions.create('transform', {
//       duration: theme.transitions.duration.shortest,
//     }),
//   },
//   expandOpen: {
//     transform: 'rotate(180deg)',
//   },
//   avatar: {
//     backgroundColor: red[500],
//   },
// });

class AssetCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeRotate1: "",
      activeRotate2: ""
    };
  }

  componentDidMount() {
    const { classes } = this.props;
    var rotatingCards = document.getElementsByClassName(classes.cardRotate);
    for (let i = 0; i < rotatingCards.length; i++) {
      var rotatingCard = rotatingCards[i];
      var rotatingWrapper = rotatingCard.parentElement;
      var cardWidth = rotatingCard.parentElement.offsetWidth;
      var cardHeight = rotatingCard.children[0].children[0].offsetHeight;
      rotatingWrapper.style.height = cardHeight + "px";
      rotatingWrapper.style["margin-bottom"] = 30 + "px";
      var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
      var cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
      cardFront.style.height = cardHeight + 35 + "px";
      cardFront.style.width = cardWidth + "px";
      cardBack.style.height = cardHeight + 35 + "px";
      cardBack.style.width = cardWidth + "px";
    }
  }

  render() {
    const { classes } = this.props;
    const { asset } = this.props;
    return (
      <div
        className={`${classes.rotatingCardContainer} ${
          classes.manualRotate
        } ${this.state.activeRotate2}`}
      >
        <Card className={classes.cardRotate}>
          <div
            className={`${classes.front} ${
              classes.wrapperBackground
            }`}
            style={{ backgroundImage: `url(${asset.attributes.adjunto_medium})` }}
          >
            <CardBody background className={classes.cardBodyRotate}>
              <h6 className={classes.cardTitleWhite}>
                {asset.attributes.name}
              </h6>

              <h6 className={classes.cardCategoryWhite}>
                Price: {asset.attributes.precio}
              </h6>
              <div className={classes.textCenter}>
                <Button
                  round
                  color="danger"
                  onClick={() =>
                    this.setState({
                      activeRotate2: classes.activateRotate
                    })
                  }
                >
                  <Refresh /> Rotar
                </Button>
              </div>
            </CardBody>
          </div>
          <div
            className={`${classes.back} ${
              classes.wrapperBackground
            }`}
            style={{ backgroundImage: `url(${asset.attributes.adjunto_medium})` }}
          >
            <CardBody background className={classes.cardBodyRotate}>
              <h5 className={classes.cardTitleWhite}>
                <Link to={'/offer/' + asset.id }>
                  REF: {asset.attributes.ref}
                </Link>
              </h5>
              <p className={classes.cardDescriptionWhite}>

                <h6 className={classes.cardDescriptionWhite}>
                  {asset.attributes.short_description}
                </h6>

              </p>
              <div className={classes.textCenter}>
                <Link to={'/offer/' + asset.id }>
                  <Button round justIcon color="info">
                    <Subject />
                  </Button>
                </Link>
                <Link to={'/contact-us' }>
                  <Button round justIcon color="success">
                    <Icon>email</Icon>
                  </Button>
                </Link>
                <br />
                <br />
                <Button
                  round
                  color="danger"
                  onClick={() =>
                    this.setState({
                      activeRotate2: ""
                    })
                  }
                >
                  <Refresh /> Volver...
                </Button>
              </div>
            </CardBody>
          </div>
        </Card>
      </div>
    );
  }
}

AssetCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssetCard);
