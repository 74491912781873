import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Separator from "@material-ui/icons/LinearScale";
import Bullet from "@material-ui/icons/DragIndicator";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>¿Qué ofrecemos?</h2>
          <h5 className={classes.description}>
            La cultura de asociación de Kinos, acceso en red a miles de colaboradores, nuestro compromiso con la excelencia y
            la integridad intransigente nos convierte en una excelente opción para inversores con una visión a corto, medio o largo plazo..
          </h5>

          <h5 className={classes.description}>El interés de nuestro cliente primero es el núcleo de nuestra filosofía de gestión de activos inmobiliarios o empresariales. También adaptamos sus estrategias de inversión a las necesidades específicas de los clientes.
</h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="SERVICIOS DE ASESORÍA Y TRANSACCIÓN"
            description={
              <span>
                <p>
                  Con nuestros especialistas dedicados en nuestro negocio de servicios de transacciones, podemos brindarle a usted, nuestro cliente, una combinación de conocimientos inmobiliarios, financieros, comerciales y operativos para cada trato u operación. Brindamos un conocimiento incomparable mientras navegamos el proceso de negociación con usted. Ya sea que esté realizando una adquisición, una des-inversión o una alianza estratégica, en cada caso tenemos el mismo objetivo: asegurarnos de que obtenga el máximo rendimiento de su operación.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="GESTIÓN Y ADMINISTRACIÓN DE PROPIEDADES"
            description={
              <span>
                <p>
                  Gestionamos estratégicamente sus activos inmobiliarios para incrementar su valor En Kinos entendemos que tenemos tres clientes en la gestión de activos: el propietario, los inquilinos y los clientes relacionados y proveedores con ambos. Nuestros equipos de gestión utilizan herramientas innovadoras y eficaces en un entorno altamente colaborativo y nacional e internacional. donde se comparten conocimientos. El Acceso colaborativo de todos los profesionales y usuarios a una sola plataforma de gestión de Kinos para casa activo. 
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="RELACIONES"
            description={
              <span>
                <p>
                  Kinos se dedica a encontrar soluciones a medida para satisfacer las necesidades de nuestros miembros en nuestra amplia red de experiencia, conocimiento y relaciones. Construimos relaciones cruciales y sólidas. Formando confianza. Establecer relaciones es fundamental para el éxito de las operaciones comerciales. Kinos mantiene una comunicación sólida y constante con inversores, propietarios y una gran red de profesionales.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <InfoArea
            title="VENTA DE PROPIEDADES"
            description={
              <span>
                <p>
                  Property Sales ofrece disposiciones de inversión personalizadas, servicios de adquisición y recapitalización, junto con inteligencia de mercado líder en la industria, para brindar a nuestros clientes una exposición inigualable a los compradores validando cada perfil.  Nuestros profesionales de ventas Inmobiliarias están conectados con sus redes de pares  de otros profesionales  en España y a nivel internacional en principales ciudades en todo el mundo, brindando servicios de inversión y asesoramiento a inversores, empresas, instituciones públicas, family office y otros fondos o sofisticados inversores de capital institucional.
                </p>

              </span>
            }
            icon={Bullet}
            iconColor="rose"
          />
      </GridItem>
      <GridItem md={6} sm={6}
        className={classNames(
          classes.mlAuto,
          classes.mrAuto,
          classes.textCenter
        )}
        >
        <h3 className={classes.title}>Estrategias de inversión</h3>
          <span>
            <p>
              <h5>Gestión de JV o Proceso de Desarrollo</h5>
              <h5>Modelado operativo financiero</h5>
              <h5>Presupuesto y control</h5>
              <h5>Estructura y especificaciones legales</h5>
              <h5>Interacción habilitada por la tecnología</h5>
              <h5>Informe financiero</h5>
              <h5>Informe operativo</h5>
              <h5>Estrategia de Marketing</h5>
              <h5>Estratgias de venta</h5>
              <h5>Análisis de costes del ciclo de vida</h5>
              <h5>Posicionamiento de activos</h5>
              <h5>Estrategias de salida</h5>
            </p>

          </span>
      </GridItem>

      <GridItem md={6} sm={6}
        className={classNames(
          classes.mlAuto,
          classes.mrAuto,
          classes.textCenter
        )}
        >
        <h3 className={classes.title}>Canales de inversión</h3>
          <span>

              <h6>Inversiones directas</h6>
              <h5>En activos inmobiliarios </h5>
              <h5>En proyectos</h5> 
              <h6>A través de intermediarios</h6>
              <h5>Gestión de inversiones </h5>
              <h5>Fondos de capital privado </h5>
              <h5>Fondos de infraestructura</h5> 
              <h5>Socimis</h5> 
              <h5>Reits</h5> 
              <h6>Otros vehículos de inversión conjunta...</h6>

          </span>
      </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(servicesStyle)(SectionServices);
