import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components

import SectionDestacadas from "./SectionDestacadas.jsx";
import SectionRecents from "./SectionRecents.jsx";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const { featured } = props;
  const { recents } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>

Nuestro equipo ofrece asesoramiento en leasing, adquisiciones, consultoría e inversiones a minoristas y propietarios en toda España.
          </h3>
          <p>


            Operando en nueve oficinas en todo el país, nuestro equipo intersectorial son expertos en sus mercados minoristas locales, aplicando sus conocimientos a cada proyecto y cliente individual, cubriendo todo, desde centros comerciales hasta minoristas de la calle principal, y desde supermercados hasta parques comerciales.

            En un sector en rápida evolución que continúa siendo remodelado por la demanda de los consumidores, nuestro equipo conoce la importancia de mantenerse al día con sus desarrollos. Desde esquemas dentro y fuera de la ciudad, hasta grandes tiendas, almacenes y tiendas individuales, cubrimos todas las partes del sector de la propiedad comercial en todas las áreas de España, operando desde Madrid, Málaga y Barcelona.

            El tamaño del equipo y la distribución geográfica nos permite responder rápidamente a los requisitos de los clientes en cualquier escala de proyecto, y también proporcionar experiencia a escala nacional para entregar de manera efectiva y eficiente.

            Ofrecemos un servicio especializado y a medida en todos los aspectos técnicos del asesoramiento de inversiones, adquisiciones, enajenaciones y consultoría de arrendamientos en toda España proporcionando un enlace con Europa, así como ampliando nuestra red global.
            AL POR MENOR

          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“¡La excelencia es nuestro objetivo, la satisfacción de los clientes es nuestro orgullo!”"
            author="Joaquín Morales, CEO."
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section}>
          <SectionDestacadas featured= {recents} />
          <SectionRecents recents= {recents} />
        </GridItem>

        <GridItem xs={12} sm={8} md={8}   id="search" >
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
